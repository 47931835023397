<script>
export default {
  name: "UserOverivew",

  props: {
    userId: [String, Number]
  },

  data() { return {
    overviewData: {},
    waitResponse: false
  }},

  created()
  {
    this.RequestOverviewData();
  },

  methods: {
    RequestOverviewData()
    {
      this.waitResponse = true;
      this.$api.get({ path: `users/${this.userId}/overview` }, this.OnReceiveData)
    },

    OnReceiveData({response})
    {
      this.overviewData = response.data;
      this.waitResponse = false;
    }
  }
}
</script>


<template>
  <div class="user-overview" v-if="waitResponse">
    <div class="center-box">
      <loading></loading>
    </div>
  </div>

  <div class="user-overview" v-else>
    <div class="config-row">
      <icon>splitscreen</icon>Total taskuri programate: {{overviewData.priorty_tasks}}
    </div>
    <div class="config-row">
      <icon>schedule</icon>Total taskuri expirate: {{overviewData.expired_tasks}}
    </div>
    <div class="config-row">
      <icon>hourglass_top</icon>Total taskuri active: {{overviewData.active_tasks}}
    </div>
    <div class="config-row">
      <icon>done</icon>Total taskuri terminate: {{overviewData.done_tasks}}
    </div>
    <div class="config-row">
      <icon>today</icon>Total taskuri zilnice: {{overviewData.daily_tasks}}
    </div>
  </div>
</template>


<style scoped>
.user-overview {
  flex-grow: 1;
}

.user-overview .config-row {
  gap: 5px;
}
</style>